import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from "../sections/home/hero"
import HostChallenge from "../sections/home/host-challenge"
import PlaySuperBetter from "../sections/home/play-superbetter"
import Impact from "../sections/home/impact"

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="SuperBetter: Empower Youth Mental Health"
        description="SuperBetter is a game-based learning platform that empowers mental health, resilience, social-emotional learning and student success."
        keywords="youth mental health, social-emotional learning, student success, resilience"
      />
      <Hero />
      <HostChallenge />
      <PlaySuperBetter />
      <Impact />
    </Layout>
  )
}

export default IndexPage
