import { Link } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"

const Impact = () => {
  return (
    <section id="home-impact" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col md="12">
            {/* <h1>Global impact</h1> */}
            <h3>
            “A Top Innovator in Youth Mental Health”
            </h3>
            <h4>- The World Economic Forum</h4>
            <Link to="/our-story" className="read-our-story mx-auto">
            Our Story
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Impact
