import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {  Col, Row } from "react-bootstrap"
import VideoPopup from "../../components/VideoPopup";
const HostChallenge = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <section id="home-host-challenge" className="position-relative py-5">
      <VideoPopup videoId="https://vimeo.com/841924819/14b0a296c6" id="RenYleoiQHo"  show={modalShow} onHide={() => setModalShow(false)} />
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>Host challenges for squads</h1>
          </Col>
          <Col md="6" className="py-5">
            <StaticImage
              src="../../images/hero-4-O.webp"
              quality={100}
              formats={["AUTO", "WEBP"]}
              placeholder="New! Host Challenges for Squads"
              alt="New! Host Challenges for Squads"
              className="img-fluid about-mira-01"
            />
          </Col>
          <Col md="6" className="d-flex flex-column justify-content-start py-5">
            <div>
              <h4>
              A versatile tool designed for educators
              </h4>
              <ul>
                <li>
                Middle & high school teachers bring social-emotional learning to life.
                </li>
                <li>Youth programs, teams & clubs promote mental health & resilience.</li>
                <li>Universities empower student success and student wellbeing.</li>
                <li>Small businesses use it as an affordable employee wellbeing program.</li>
                <li>Just $99/year to host challenges for up to 30 people.</li>
              </ul>

            <div className="d-flex ">
              <div
                // href={`${process.env.HOST_APP}signup`}
                className="host-account"
                
                onClick={() => setModalShow(true)}
              >
              Watch a Demo
              
              <StaticImage   src="../../images/play-btn-blue.svg" formats={["AUTO", "WEBP", "SVG"]}  alt="watch a demo superbetter" className="ms-2"  />
              </div>

              <a
                href={`${process.env.HOST_APP}signup`}
                className="host-account-filled"
                target="_blank"
                rel="noopener noreferrer"
              >
             30 Day Free Trial
              </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default HostChallenge
