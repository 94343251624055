import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Image, Row } from "react-bootstrap"
import Hero5Image from "../../images/hero-5.webp"
import PlaySuperBetterBG from "../../images/play-superbetter-bg.svg";
import VideoPopup from "../../components/VideoPopup"

const PlaySuperBetter = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <section
      id="home-host-challenge"
      className="position-relative py-5"
      style={{
        backgroundColor: "#FFEDD1",
        backgroundImage: `url('${PlaySuperBetterBG}')`,
      }}
    >
      <VideoPopup videoId="https://vimeo.com/841926047/299149a36e" id="UJV_FpxZw0Q"  show={modalShow} onHide={() => setModalShow(false)} />
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>Play SuperBetter on your own</h1>
          </Col>
          <Col
            md="6"
            className="py-5 d-flex flex-column justify-content-center"
          >
            <div>
              <h4>The Hero account</h4>
              <ul>
                <li>
                Grow your mental health, emotional wellbeing, social relationships and 
   physical resilience with a library of 40+ solo challenges.


                </li>
                <li>
                Create custom challenges to go for personal epic wins.
                </li>
                <li> Join squad challenges from hosts.</li>
                <li>Only $24.99/year.</li>
              </ul>

              <div className="d-flex">
              <a
                // href={`${process.env.WEB_APP}auth/register`}
                className="play-now"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setModalShow(true)}
              > 
               Watch a Demo
               <StaticImage  src="../../images/play-btn-orange.svg" quality={100} alt="watch a demo superbetter" className="img-fluid ms-2"  />
              </a>
              <a
                href={`${process.env.WEB_APP}auth/register`}
                className="play-now-filled"
                target="_blank"
                rel="noopener noreferrer"
              > 
               14 Day Free Trial

              </a>
              </div>
              
            </div>
          </Col>
          <Col md="6" className="d-flex justify-content-center py-5">
            <Image
              src={Hero5Image}
              style={{ maxWidth: 500, maxHeight: 500 }}
              quality={100}
              formats={["AUTO", "WEBP", "SVG"]}
              placeholder="Play with Superbetter"
              alt="Play with Superbetter"
              className="img-fluid about-mira-01"
            />
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default PlaySuperBetter
