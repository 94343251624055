import React from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import ReactPlayer from "react-player";
import PlayIcon from "../../images/play-icon-light.png";
import PauseIcon from "../../images/pause-icon.png";
import SquadPlay from "../../images/squad-play.png";


const Hero = () => {
  const [playing , setPlaying] = React.useState(false);
  const [startVideo, setStartVideo] = React.useState(false);
  const playerRef = React.useRef();

  const handledOnEnded = React.useCallback(() => {
    playerRef.current.showPreview();
    setStartVideo(false);
  }, []);

  return (
    <section
      id="home-hero"
      className="pt-5 d-flex align-items-end justify-content-center"
    >
      <div className="container h-100  mt-5 pt-5">
        <Row>
          <Col
            md="6"
            className="hero-text py-5 mb-5 d-flex justify-content-center align-items-start flex-column"
          >
            <h1>Empower youth mental health </h1>
            <p>
            Promote youth mental health, resilience, social-emotional learning and student success in a way that is practical & engaging.
            </p>
            <p className="mb-5">
            The SuperBetter app uses the psychology of game play to achieve epic wins in all of life. Over 1 million people have played SuperBetter.
            </p>
            <Link
              to="/the-science"
              className="btn btn-outline-primary"
              style={{ minWidth: 165 }}
            >
              Backed by Science
            </Link>
          </Col>
          <Col md="6" className="py-5 mb-5 hero-bg-rightside d-flex justify-content-center align-items-center  video-section" >
               <ReactPlayer
                url='https://vimeo.com/841924804/e9237d0304'
                className="home-video"
                playing={playing}
                onEnded={(e) => handledOnEnded()}
                ref={playerRef}  
                fallback={<img src={SquadPlay} />}
                controls={true}
                onPlay={(e) => { setPlaying(true); setStartVideo(true); }}
                onPause={(e) => { setPlaying(false); setStartVideo(false); }}
               />
            {!playing && !startVideo && <img src={PlayIcon} className="play-icon cursor" onClick={() => { setPlaying(!playing); setStartVideo(!startVideo); }} />}
            {playing && startVideo && <img src={PauseIcon} className="pause-icon cursor" onClick={() => { setPlaying(!playing); setStartVideo(!startVideo); }} />}
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Hero
